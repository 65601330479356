import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>
                    Curs Presencial Preparació Adequació Psicoprofessional Mossos d'Esquadra 2024
                    [Grup Extra]
                </Title>
                <Text>-</Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
